import './App.css';
import { Box } from '@mui/material'
import InvalidDoc from './Components/Document/Invalid/InvalidDoc';
import Otp from './Components/Document/Valid/Otp';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GET_CSRF, POST } from './Helpers/http';
import Loading from './Components/Loading/Loading';
import DisableRightClick from './Components/RightClickDisable';

function App() {
    const { pathname } = useLocation()
    const [csrfValue, setCsrfValue] = useState('')

    const tenantNameAccessKey = pathname.replace('/access/', '')

    const tenantName = tenantNameAccessKey.split('/')?.[0]

    const accessKey = tenantNameAccessKey.split('/')?.[1]

    const [component, setComponent] = useState("") //invalid, expired, valid

    const [shareAccess, setShareAccess] = useState({})

    useEffect(() => {
        GET_CSRF(setCsrfValue)
    }, [])
    useEffect(() => {
        //api call
        // if (tenantName && accessKey) {}
        if (csrfValue) {
            POST(`${process.env.REACT_APP_BASE_URL}doc/v1/shares/public/verify/${tenantName}/${accessKey}`, {}, "Verify Document Access", csrfValue, setCsrfValue)
                .then(response => {
                    if (response?.status === 200) {
                        const data = response.data
                        setComponent('otp')
                        setShareAccess(data)
                    }
                })
                .catch(error => {
                    console.log({ error })
                    if (error?.response?.status === 404) {
                        setComponent('invalid')
                    }
                })
        }
        // }
        // else {
        //     setComponent('invalid')
        // }
    }, [csrfValue, tenantName, accessKey])

    const handleContextMenu = (e) => {
        e.preventDefault()
    }

    useEffect(() => {
        document.addEventListener('contextmenu', handleContextMenu)

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu)  //After event handler is executed,remove it to prevent memeory leaks
        }
    }, [])

    //clickJacking prevention - frameBusting code
    useEffect(() => {
        if (window !== window.top) {
            window.top.location = window.location
        }
    }, [])

    return (
        <Box>
            <DisableRightClick />
            {
                component === "otp" ? <Otp shareAccess={shareAccess} tenantName={tenantName} csrfValue={csrfValue} setCsrfValue={setCsrfValue} /> : component === "invalid" ?
                    <InvalidDoc /> : <Loading />
            }
        </Box>
    );
}

export default App;
