import React, { useEffect } from 'react';

const DisableRightClick = () => {
    useEffect(() => {
        // Disable right-click on the entire window
        const disableRightClick = (e) => {
            e.preventDefault();
        };

        window.addEventListener('contextmenu', disableRightClick);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('contextmenu', disableRightClick);
        };
    }, []);

    return null; // This component doesn't render anything
};

export default DisableRightClick;