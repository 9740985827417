import React from 'react'
import { Box, Typography } from '@mui/material'
import { ReportProblemOutlined } from '@mui/icons-material'

const InvalidDoc = () => {
    return (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'80vh'}>
            <Box>
                <Typography fontWeight={600} align='center'>
                    <ReportProblemOutlined sx={{ fontSize: 200 }} />
                </Typography>
                <Typography fontWeight={600} align='center'>Invalid or Expired Link</Typography>
                <Typography align='center' mt={2}>The link you are accessing is either expired or invalid. For more information please contact the document owner</Typography>
            </Box>
        </Box>
    )
}

export default InvalidDoc