import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import DocPreview from './DocPreview';
import Header from '../../Header/Header';
import { POST } from '../../../Helpers/http';

const Otp = (props) => {
    const { shareAccess, tenantName, csrfValue, setCsrfValue } = props

    const [otp, setOtp] = useState("");

    const [errorMessage, setErrorMessage] = useState('')

    const [documentDetails, setDocumentDetails] = useState({})

    const handleClearOtp = () => {
        setOtp('')
        setErrorMessage('')
    }

    const handleSubmitOtp = () => {
        if (otp.length === 6) {
            POST(`${process.env.REACT_APP_BASE_URL}doc/v1/shares/public/validate/${tenantName}`, {
                otp: Number(otp),
                email: shareAccess.email,
                secret: shareAccess.secret

            }, "Verify Document Access", csrfValue, setCsrfValue)
                .then(response => {
                    if (response.status === 200) {
                        const data = response.data
                        setDocumentDetails(data)
                    }
                })
                .catch(error => {
                    if (error.response?.status === 401 || error.response?.status === 404) {
                        setErrorMessage(error.response?.data?.error)
                    } else {
                        console.log({ error })
                    }

                })
        }
    }

    // const [minutes, setMinutes] = useState(1);

    // const [seconds, setSeconds] = useState(0);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (seconds > 0) {
    //             setSeconds(seconds - 1);
    //         }

    //         if (seconds === 0) {
    //             if (minutes === 0) {
    //                 clearInterval(interval);
    //             } else {
    //                 setSeconds(59);
    //                 setMinutes(minutes - 1);
    //             }
    //         }
    //     }, 1000);

    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [seconds])


    return (
        <>
            <Header documentDetails={documentDetails} />
            <Box height={'80vh'} display={'flex'} justifyContent={'center'} alignItems={'center'} >
                {Object.keys(documentDetails)?.length > 0 ? <DocPreview documentDetails={documentDetails} /> :
                    <Box border={'solid'} borderRadius={'8px'} p={3}>
                        <Typography align='center' fontWeight={700} mb={6} fontSize={'20px'}>Enter Verification OTP</Typography>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={{
                                width: 60,
                                height: 50,
                                fontSize: 32,
                                borderRadius: 10,
                                marginRight: 20,
                            }}
                        />
                        {errorMessage && <Typography mt={2} align='right' color={'red'}>{errorMessage}</Typography>}
                        {/* <Box display={'flex'} justifyContent={'space-between'} mt={errorMessage ? 2 : 6} >
                            <Typography >Didn't receive code?</Typography>
                            <Typography sx={{ cursor: 'pointer', textDecoration: 'underline', color: '#dc4d28' }} >Resend OTP</Typography>
                        </Box>
                        {
                            seconds > 0 || minutes > 0 ? (
                                <p>
                                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                    {seconds < 10 ? `0${seconds}` : seconds}
                                </p>
                            ) : (
                                <p>Didn't recieve code?</p>
                            )
                        } */}
                        <Box display={'flex'} justifyContent={'space-evenly'} mt={errorMessage ? 2 : 6} >
                            <Button
                                size='large'
                                variant='contained'
                                onClick={handleClearOtp}
                            >
                                Clear
                            </Button>
                            <Button
                                size='large'
                                variant='contained'
                                disabled={otp.length !== 6}
                                onClick={handleSubmitOtp}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                }
            </Box>
        </>
    )
}

export default Otp