import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { saveAs } from "file-saver";

const DocPreview = ({ documentDetails }) => {
    const handleDownloadDocument = () => {
        fetch(documentDetails?.url, {
            headers: { "Cache-Control": "no-cache" }

        })
            .then((response) => response.blob()) // Convert the response to a Blob
            .then((blob) => {
                // Use the file-saver library to trigger the download
                saveAs(blob, documentDetails?.title);
            })
            .catch((error) => {
                console.error("Error downloading the file:", error);
            });
    }

    return (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'80vh'}>
            <Box>
                <Typography fontSize={'20px'} fontWeight={700} mb={1}>Document Preview</Typography>
                <embed
                    style={{ border: '1px dotted', borderRadius: '8px' }}
                    src={`${documentDetails?.url}#toolbar=0`}
                    width="100%"
                    height="600"
                />

                <Button
                    variant='outlined'
                    onClick={handleDownloadDocument}
                    sx={{ mt: 1 }}
                >
                    Download
                </Button>
            </Box>
        </Box>
    )
}

export default DocPreview