import axios from 'axios';
import { toast } from 'react-toastify';
// import store from '../Redux/store/store';

// const state = store.getState()

const setConfigObj = (csrfValue) => {
    let config = {}
    config.headers = {
        'Content-Type': 'application/json',
        'x-csrf-token': csrfValue
    }

    config.withCredentials = true

    return config
}
export const errorHandler = (error, label) => {
    const errorResponse = error.response
    if (
        errorResponse?.status === 400 ||
        errorResponse?.status === 403 ||
        errorResponse?.status === 406 ||
        errorResponse?.status === 409 ||
        errorResponse?.status === 500 ||
        errorResponse?.status === 502 ||
        errorResponse?.status === 503 ||
        errorResponse?.status === 504 ||
        errorResponse?.status === 'FETCH_ERROR' ||
        error.originalStatus === 400 ||
        error.originalStatus === 403 ||
        error.originalStatus === 406 ||
        error.originalStatus === 409 ||
        error.originalStatus === 500 ||
        error.originalStatus === 502 ||
        error.originalStatus === 503 ||
        error.originalStatus === 504 ||
        error.originalStatus === 'FETCH_ERROR' ||
        error.status === 400 ||
        error.status === 403 ||
        error.status === 406 ||
        error.status === 409 ||
        error.status === 500 ||
        error.status === 502 ||
        error.status === 503 ||
        error.status === 504 ||
        error.status === 'FETCH_ERROR'
    ) {
        toast.error(`${label}: ${errorResponse?.data?.error || errorResponse?.data?.message || error?.data?.error?.[0]?.message || error?.data?.message || error?.data?.error || error?.error}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    if (errorResponse?.status === 401 || error.originalStatus === 401 || error.status === 401) {
        toast.error(`Invalid Token`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        localStorage.clear()
        // window.location.href = `${process.env.REACT_APP_BASE_URL}`
    }
}

export const POST = (endpoint, body, label, csrfValue, csrfUpdateFunc, showCsrfError) => {
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.post(endpoint, body, setConfigObj(csrfValue))
            resolve(response)
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 400 && error?.response?.data?.error === "Invalid csrf token") {
                GET_CSRF(csrfUpdateFunc)
                if (showCsrfError)
                    errorHandler(error, label)
            }
            else errorHandler(error, label)
            reject(error);
        }
    });
};


export const FILEPOST = (endpoint, body, label, csrfValue, setCsrfValue, showCsrfError) => {
    return new Promise(async function (resolve, reject) {
        try {
            let fileHeaders = {};

            const configObj = {}
            fileHeaders['Content-Type'] = 'multipart/form-data';
            fileHeaders['x-csrf-token'] = csrfValue;
            configObj.withCredentials = true
            configObj.headers = fileHeaders

            const response = await axios.post(endpoint, body, { headers: fileHeaders })
            resolve(response)
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 400 && error?.response?.data?.error === "Invalid csrf token") {
                GET_CSRF(setCsrfValue)
                if (showCsrfError) errorHandler(error, label)
            }
            else errorHandler(error, label)
            reject(error);
        }
    });
};

export const PUT = (endpoint, body, label, csrfValue, setCsrfValue, showCsrfError) => {
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.put(endpoint, body, setConfigObj(csrfValue))
            resolve(response);
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 400 && error?.response?.data?.error === "Invalid csrf token") {
                GET_CSRF(setCsrfValue)
                if (showCsrfError) errorHandler(error, label)
            }
            else
                errorHandler(error, label)
            reject(error);
        }
    });
};

export const GET = (endpoint, label) => {
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.get(endpoint, { headers: { 'Authorization': localStorage.getItem('token'), 'Content-Type': 'application/json' } })
            resolve(response);
        } catch (error) {
            console.log(error);
            errorHandler(error, label)
            reject(error);
        }
    });
};

export const DELETE = (endpoint, label, csrfValue, setCsrfValue, showCsrfError) => {
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.delete(endpoint, setConfigObj(csrfValue))
            resolve(response);
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 400 && error?.response?.data?.error === "Invalid csrf token") {
                GET_CSRF(setCsrfValue)
                if (showCsrfError) errorHandler(error, label)
            }
            else errorHandler(error, label)
            reject(error);
        }
    });
};

export const GET_CSRF = async (updateCallback) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}doc/v1/csrf`, { withCredentials: true })
        if (response?.status === 200) {
            if (updateCallback) updateCallback(response?.data?.csrf_token)
        }
    } catch (error) {
        console.log(error);
    }
}