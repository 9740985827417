export const headerCard = {
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    headerGrid: {
        display: 'flex',
        alignItems: 'center',
    },
    box: {
        display: 'flex',
        alignItems: 'center',
    },
    sentDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    sentDetailsTypography: {
        fontSize: '15px'
    }
    // avatarIcon: {
    //     height: 25, width: 25
    // },
    // logoutCard: {
    //     display: "row",
    //     minWidth: '230px',
    //     minHeight: '120px',
    //     backgroundColor: 'black',
    //     color: 'white',
    //     p: 2,
    //     borderRadius: '6px',
    // },
    // signoutButton: {
    //     backgroundColor: '#f00',
    //     color: 'black',
    //     '&:hover': {
    //         background: '#f00',
    //         color: 'white',
    //     },
    // },
    // cancelButton: {
    //     backgroundColor: 'grey',
    //     color: 'black',
    //     '&:hover': {
    //         background: 'darkgray',
    //         color: 'white',
    //     },
    // },
}